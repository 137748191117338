<!-- 学科优势报告 mobile -->
<template
  ><div class="main">
    <div class="print-page" id="cover" name="cover">
      <h1 class="lj-text-center">学科优势报告</h1>
      <p>
        学科优势测评报告是在测评数据的支持下，从学生兴趣出发，针对学生测评结果对学生学科优势做出的分析。通过此项测试，可帮助了解各科情况同时根据各项能力值给到匹配的选科与专业推荐
      </p>
      <div class="part-content" id="container" style="min-height: 480px"></div>
      <div class="button-wrapper">
        <a
          class="lj-btn lj-btn-secondary"
          @click="jumpPage('reportCenter')"
          style="margin-right: 1rem;"
          >返回测评导航</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import './../../../../Public/utils/chartjs/Chart'
import './../../../../Public/utils/highcharts/highcharts'
import { querySubject } from './../../../../api/major'
import Highcharts from './../../../../Public/utils/highcharts/highcharts'

export default {
  data: function () {
    return {
      score: [76, 23],
      subject: ['计算机', '金融'],
      questionaireDone: true,
      chart: {}
    }
  },
  computed: {
    chartOptions: function () {
      return {
        chart: { type: 'bar' },
        xAxis: {
          categories: this.subject,
          title: {
            text: '专业类',
            style: { fontSize: '16px' }
          },
          labels: {
            step: 1,
            staggerLines: 1,
            style: { fontSize: '14px' }
          }
        },
        title: { text: null },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: '匹配度',
            align: 'high',
            style: { fontSize: '16px' }
          },
          labels: { format: '{value}' }
        },
        legend: { enabled: !1 },
        tooltip: { valueDecimals: 2, valueSuffix: '' },
        plotOptions: {
          bar: {
            dataLabels: { enabled: !0, format: '{y}' }
          },
          series: { pointWidth: 30 }
        },
        credits: { enabled: !1 },
        series: [{ name: '匹配度', data: this.score }]
      }
    },
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  methods: {
    print: function () {
      this.$router.push({
        path: 'HomeMajorStdPrint'
      })
    },

    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },

    initChart () {
      this.chart = new Highcharts.Chart(
        document.getElementById('container'),
        this.chartOptions
      )
    }
  },
  mounted: function () {
    if (this.isMobile === null) {
      this.$router.push({
        path: 'HomeMajorStdResultPage2'
      })
      return
    }
    let that = this
    querySubject({}).then(res => {
      if (res.rescode == 200) {
        console.log('querySubject', res)
        this.score = res.data.score
        this.subject = res.data.subject
        this.initChart()
      }
    })
  }
}
</script>

<style lang="less" scoped>
.mbti-describe {
  text-align: left;
}
@import url('./../../../../Public/resources/css/templates/home/major/std_mobile_result.min.css');
</style>
